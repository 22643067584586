<template>
    <div class="container">
      <div class="box red" style="overflow-y: auto;">
        <div ref="chartContainer" class="chart-container" style="margin-top: 40px;" ></div>
        <div v-if="apiSummary" class="summary-container">{{ apiSummary }}</div>
        <!-- 显示总结内容 -->
        <div v-if="titles.length > 0" class="summary-container">
      根据以上分析结果，数据<span v-if="showWarnButton" style="color:red; font-weight: 600;">不达标</span><span v-if="!showWarnButton"  style="color:green; font-weight: 600;">达标</span>，我一并给出<span style="font-weight: 600;color: rgb(var(--primary-default));">{{ titles.join(' / ') }}</span>两张分析报表供您进行数据分析。
    </div>
      <div class="fccc">
        <el-button v-if="showWarnButton" type="danger" @click="open4" size="large" style="font-size: 18px;margin-bottom: 40px;" >发送邮件通知相关负责人</el-button>
      </div>
    
      </div>
      <div class="box yellow fccc">
        <div v-if="isLoadingYellow" class="loading-message">
          <TF2LoadVue></TF2LoadVue>
        </div>
        <div ref="yellowContainer" class="chart-container" style="height: 40vh;"></div>
      </div>
      <div class="box blue fccc">
        <div v-if="isLoadingBlue" class="loading-message">
          <TF2LoadVue></TF2LoadVue>
        </div>
        <div ref="blueContainer" class="chart-container"  style="height: 40vh;"></div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from "vue";
  import * as echarts from "echarts";
  import TF2LoadVue from '../components/TF2Load.vue';
  import { ElNotification } from 'element-plus'
  
  export default {
    name: "TF2echartDataAnalysis",
    components:{
      TF2LoadVue,
    },
    props: {
      echartsConfig: Object,
      userInput: String,
    },
    setup(props) {
      const apiSummary = ref(""); // 用来存储第三次 API 的返回内容
      const additionalData = ref(null); // 用来存储附加 API 的返回内容
      const isLoadingYellow = ref(true); // yellowContainer 的加载状态
      const isLoadingBlue = ref(true); // blueContainer 的加载状态
      const titles = ref([]); // 用来存储提取到的title
      const showWarnButton = ref(false); // 用于控制是否显示警告按钮
  
      console.log("props.userInput" + props.userInput);
  
      // 动态构建 prompt03
      const prompt03 = `
  你是由TRANSFACT公司开发的T-AI，用于回答生产相关的问题。
  
  ### 已知信息：
  1. **KPI考核相关指标**: 设备稼动率额定为 50%；完工率KPI额定为80%；
  2. **查询得到的结果**: "${props.userInput}"
  3. **图表信息**:
     - **图表标题**: "${props.echartsConfig.title}"
     - **图表类型**: "${
       props.echartsConfig.echartsStyle === "pie" ? "饼状图" : "柱状图"
     }"
     - **图表数据**: ${JSON.stringify(props.echartsConfig.data)}
  
  ### 任务：
  基于上述信息，针对“图表信息的内容”请生成一段简短的总结（可根据数据复杂度来选择输出的字数，数据越多越复杂，则输出的内容越多，但是最多不要超过100字），总结内容仅限于对图表信息的分析。- **输出语言**: 判断以下内容，“语种示例：【${props.echartsConfig.title}】”中所使用的语言，来进行对应语言的输出。并按照以下格式返回：
  
  **数据分析**：简短总结内容
  
  其中，“图”根据图表类型（${
        props.echartsConfig.echartsStyle
      }）填写“饼状图”或“柱状图”；“简短总结内容”应对查询的内容表现进行评价，并确保总结内容清晰、准确。
  `;
  
      console.log("prompt03" + prompt03);
  
      // 第一次调用API进行数据分析
      const fetchSummary = async () => {
        try {
          const response = await fetch("https://api.fe8.cn/v1/chat/completions", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer sk-CqOHiuAoLaAS4Qd36IazH3HGWgn7fF9FO9S9jvNKDPVBAIbp", // 替换为您的API key
            },
            body: JSON.stringify({
              model: "gpt-4o-mini",
              messages: [{ role: "system", content: prompt03 }],
              stream: true, // 使stream为true以启用流式输出
            }),
          });
  
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          const reader = response.body.getReader();
          const decoder = new TextDecoder("utf-8");
          let done = false;
  
          apiSummary.value = ""; // 初始化清空
  
          while (!done) {
            const { value, done: readerDone } = await reader.read();
            done = readerDone;
            const chunk = decoder.decode(value, { stream: !readerDone });
  
            // 处理流数据，逐步拼接到apiSummary
            const lines = chunk.split("\n").filter((line) => line.trim() !== "");
            for (const line of lines) {
              if (line.startsWith("data: ")) {
                const data = line.slice(6).trim();
                if (data === "[DONE]") {
                  done = true;
                  break;
                }
                try {
                  const jsonData = JSON.parse(data);
                  if (jsonData.choices && jsonData.choices.length > 0) {
                    apiSummary.value += jsonData.choices[0].delta.content || "";
                  }
                } catch (error) {
                  console.error("Error parsing JSON:", error);
                }
              }
            }
          }
  
          console.log("Third API Summary (stream):", apiSummary.value);
  
          // 在获取总结后调用新的 API
          fetchAdditionalData(apiSummary.value);
          // 并发调用第三次API来判断是否显示Warn按钮
          fetchWarnButtonStatus(apiSummary.value);
        } catch (error) {
          console.error("Error fetching summary:", error);
        }
      };
  
      // 第二次调用API进行数据分析
      const fetchAdditionalData = async (summary) => {
        try {
          const response = await fetch("https://api.fe8.cn/v1/chat/completions", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer sk-CqOHiuAoLaAS4Qd36IazH3HGWgn7fF9FO9S9jvNKDPVBAIbp", // 替换为您的API key
            },
            body: JSON.stringify({
              model: "gpt-4o-mini",
              messages: [
                {
                  role: "system",
                  content: `
  你是由TRANSFACT公司开发的T-AI，用于回答生产相关的问题。
  
  ### 已知信息：
  1. **总结数组**: "${props}"
  1. **总结内容**: "${summary}"
  2. **输出数据**: 根据总结内容补充两个相关的数据信息。
  3. **数据关联关系**: 
     - 如果设备稼动率达标，关联数据为【本周设备稼动率】和【本周设备利用率】。
     - 如果设备稼动率不达标，关联数据为【今日设备异常原因统计】和【本周设备稼动率】。
     - 如果完工率达标，关联数据为【本周完工率】和【本周计划完成率】。
     - 如果完工率不达标，关联数据为【今日设备异常原因统计】和【本周完工率】。
  4. **关联的数据信息**:
     - 【本周设备稼动率】: {周一：67%，周二：54%，周三：67%，周四：56%，周五：52%}
     - 【本周设备利用率】: {周一：77%，周二：74%，周三：77%，周四：86%，周五：92%}
     - 【今日设备异常原因统计】: {物料问题耽误1小时，断刀耽误2小时，异常停电耽误20分钟，排产不合理耽误40分钟}
     - 【本周完工率】: {周一：89%，周二：99%，周三：97%，周四：94%，周五：52%}
     - 【本周计划完成率】: {周一：87%，周二：84%，周三：97%，周四：46%，周五：82%}
  
  5. **数据信息格式**: 两个数据信息分别生成符合以下 JSON 格式的回复，但是请一定注意，就严格按照下面的示例返回内容即可，格式还是String格式:
  示例如下：
    [ {
       "echartsStyle": "pie", 
       "title": "今日设备稼动率",
       "data": [
         {"name": "第一个数据", "value": 实际数据值},
         {"name": "第二个数据", "value": 100 - 实际数据值},
       ]
     },
    ]
  JSON格式要求：返回的内容前后部分一定不要带\`\`\` json \`\`\`这种样式,另外返回的JSON中一定不要有注释说明信息！！！
  返回的内容前后部分一定不要带\`\`\` json \`\`\`这种样式,
  返回的内容前后部分一定不要带\`\`\` json \`\`\`这种样式,
  返回的内容前后部分一定不要带\`\`\` json \`\`\`这种样式,
  返回的JSON中一定不要有注释说明信息！！！
  返回的JSON中一定不要有注释说明信息！！！
  返回的JSON中一定不要有注释说明信息！！！
  JSON格式字段内容解释：
  1. **"echartsStyle"**: 如果是显示一周的数据，并且只有一个值，比如设备利用率，则每天只有一个值，则返回bar，使用柱状图；如果是显示多个值的数据，比如今日设备异常原因统计，这种情况会存在多个值，则使用饼状图，返回pie。
  2. **"title"**: 应为用户询问的数据内容，例如 "今日设备稼动率"，"本周设备稼动率"，"今日设备异常原因统计"等
  3. **"data"**: 可根据实际情况包含多个对象，对象的内容是：
       - **"name"**: 值应为用户询问的数据内容
       - **"value"**: 为用户询问的实际数据值（例如 75）。
  `,
                },
              ],
              stream: false, // 不需要流式输出
            }),
          });
  
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          const result = await response.json();
          console.log("最新一次返回的信息" + result.choices[0].message.content);
          const parsedData = JSON.parse(result.choices[0].message.content);
          console.log("Extracted Titles:", titles.value);
  
          // 提取标题并存储到数组中
          titles.value = parsedData.map(item => item.title);
          // 解析结果
          if (result.choices && result.choices.length > 0) {
            additionalData.value = JSON.parse(result.choices[0].message.content);
            console.log(
              "111111111111111Additional Data JSON:",
              additionalData.value
            );
          }
        } catch (error) {
          console.error("Error fetching additional data:", error);
        }
      };
  
      // 第三次调用API判断是否达标并控制显示Warn按钮
      const fetchWarnButtonStatus = async (summary) => {
        try {
  
          const response = await fetch("https://api.fe8.cn/v1/chat/completions", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer sk-CqOHiuAoLaAS4Qd36IazH3HGWgn7fF9FO9S9jvNKDPVBAIbp", // 替换为您的API key
            },
            body: JSON.stringify({
              model: "gpt-4o-mini",
              messages: [
                {
                  role: "system",
                  content: `
  你是由TRANSFACT公司开发的T-AI，用于回答生产相关的问题。
  
  ### 已知信息：
  1. **总结内容**: "${summary}"
  
  ### 任务：
  基于上述信息，需要根据总结内容来判断是否达到目标要求。
  - 如果查询的这个内容达标，请返回{"showWarnButton": false}。
  - 如果查询的这个内容不达标，请返回{"showWarnButton": true}。
  `,
                },
              ],
              stream: false, // 不需要流式输出
            }),
          });
  
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          const result = await response.json();
          const parsedData = JSON.parse(result.choices[0].message.content);
  
          // 更新showWarnButton状态
          showWarnButton.value = parsedData.showWarnButton;
  
          console.log("Warn Button Status:", showWarnButton.value);
        } catch (error) {
          console.error("Error fetching warn button status:", error);
        }
      };
  
      onMounted(() => {
        fetchSummary(); // 调用第三次API并获取总结内容
      });
  
      return {
        apiSummary,
        fetchSummary,
        additionalData, // 暴露附加数据供后续使用
        isLoadingYellow,
        isLoadingBlue,
        titles, // 返回titles变量
        showWarnButton, // 返回showWarnButton变量
      };
    },
    mounted() {
      this.initChart(this.echartsConfig, 'chartContainer');
      this.initChart(); // 在mounted生命周期钩子中调用initChart
    },
    watch: {
      echartsConfig: {
        handler() {
          this.initChart(); // 当echartsConfig变化时调用initChart
          this.fetchSummary(); // 重新调用API以获取新的总结
        },
        deep: true,
      },
      additionalData: {
        handler(newVal) {
          if (newVal && newVal.length >= 2) {
            // 显示第一个图表在黄色盒子
            this.initChart(newVal[0], "yellowContainer");
            this.isLoadingYellow = false; // yellowContainer 加载完成，隐藏加载提示
            // 显示第二个图表在蓝色盒子
            this.initChart(newVal[1], "blueContainer");
            this.isLoadingBlue = false; // blueContainer 加载完成，隐藏加载提示
          }
        },
        deep: true,
      },
      showWarnButton: {
        handler(newVal) {
          if (newVal) {
            // 这里可以添加显示警告按钮的逻辑
            console.log("显示警告按钮");
          } else {
            // 这里可以添加隐藏警告按钮的逻辑
            console.log("隐藏警告按钮");
          }
        },
      },
    },
    methods: {
      initChart(chartConfig, containerRef) {
        if (!chartConfig || !this.$refs[containerRef]) return;
  
        const chart = echarts.init(this.$refs[containerRef]);
        const options = {
          title: {
            text: chartConfig.title,
            left: "center",
            top: "0%",
          },
          tooltip: {
            trigger: "item",
          },
          legend: {
            top: "90%",
            left: "center",
          },
          xAxis:
            chartConfig.echartsStyle === "bar"
              ? {
                  type: "category",
                  data: chartConfig.data.map((item) => item.name),
                }
              : undefined,
          yAxis:
            chartConfig.echartsStyle === "bar"
              ? {
                  type: "value",
                }
              : undefined,
          grid: {
            top: "10%",
            containLabel: true,
          },
          series: [
            {
              name: chartConfig.title,
              type: chartConfig.echartsStyle,
              radius: chartConfig.echartsStyle === "pie" ? "50%" : null,
              data: chartConfig.data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };
        chart.setOption(options);
      },
      open4() {
        ElNotification({
          title: '通知',
          message: '已成功发送系统通知及邮件',
          type: 'success',
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh; /* 将容器的高度设置为视口高度的100% */
    margin: 0px 40px;
  }
  
  .chart-container {
    width: 90%;
    height: 30vh; /* 或根据你的具体需求调整高度 */
  }
  
  .box {
    width: 30%;
    height: 80%;
    border-radius: 2px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  }
  
  .red {
    background-color: #fff;
    animation: fadeIn 1s ease-in-out; /* 延迟2秒后开始1秒的淡入效果 */
  }
  
  .yellow {
    opacity: 0;
    background-color: #fff;
    animation: fadeIn 1s ease-in-out 2s forwards; /* 延迟2秒后开始1秒的淡入效果 */
  }
  
  .blue {
    opacity: 0;
    background-color: #fff;
    animation: fadeIn 1s ease-in-out 2s forwards; /* 延迟2秒后开始1秒的淡入效果 */
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  
  
  
  
  .summary-container {
    margin: 40px;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 10px;
    font-size: 16px;
    color: #333;
    line-height: 2em;
  }
  
  .loading-message{
    margin-top: 60%;
  }
  
  
  
  </style>
  