<template>
    <!-- 使用 teleport 将 TF2eldrawer 移动到 body -->
    <teleport to="body">
      <TF2eldrawer v-model:drawer="drawer" />
    </teleport>
</template>

<script>
import { ref } from 'vue';
import TF2eldrawer from '../../View/TF2eldrawer.vue';

export default {
  name: "FloatingWindow",
  components: {
    TF2eldrawer,
  },
  setup() {
    const drawer = ref(true);

    return {
      drawer,
    };
  },
};
</script>

<style scoped>
.boxSize {
  position: relative;
  width: 30px;
  height: 30px;
  background-color: rgba(var(--warning-default), 1);
  cursor: pointer;
}

.boxSize:hover {
  opacity: 0.6;
}

.iconSize {
  position: relative;
  width: 28px;
  height: 28px;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  font-size: 16px;
  width: auto;
  line-height: 1.5em;
}

.user {
  color: #333;
  margin: 10px;
  text-align: RIGHT;
  padding: 12px 24px;
  background-color: #efefef;
  border-radius: 4px;
}

.ai {
  color: #333;
  font-weight: 400;
  margin: 10px;
  display: flex; /* 使内容与头像水平对齐 */
  align-items: start;
}

.avatar {
  flex-shrink: 0;
  width: 28px; /* 设置头像大小 */
  height: 28px;
  background-color: #000; /* 背景色为黑色 */
  border-radius: 50%; /* 使头像为圆形 */
  margin-right: 10px; /* 与文本间距 */
  display: flex;
  align-items: start;
  justify-content: flex-start;
  overflow: hidden; /* 隐藏溢出部分以保持圆形 */
}

.avatar img {
  height: 100%; /* 高度占满容器 */
  width: auto; /* 宽度自适应 */
  object-fit: contain; /* 保持图像比例 */
  display: block;
  /* margin: 0 auto; 水平居中 */
}

.message-content {
  margin-top: 0px;
  max-width: 80%; /* 限制消息内容的最大宽度 */
  word-wrap: break-word; /* 自动换行 */
  white-space: pre-wrap; /* 保留空格和换行符 */
  display: inline; /* 使光标紧跟文本内容 */
  align-items: end; /* 光标与文本内容垂直居中 */
}

.chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.chat-input button {
  padding: 5px 10px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.chat-input button:hover {
  background-color: #0056b3;
}

/* 光标闪烁效果 */
.blinking-cursor {
  font-weight: bold;
  font-size: 16px;
  color: #333;
  animation: blink 0.8s steps(2, start) infinite;
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}
</style>
