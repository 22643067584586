<template>
  <div>
    <div class="detailDataStyle" style="overflow: auto">
      <div>
        <div
          style="
            font-size: 24px;
            font-weight: 600;
            padding: 8px;
            color: rgb(var(--secondary-default));
            margin: 0px 20px 8px 20px;
            border-bottom: 2px solid #ccc;
          "
          class="frcsb"
        >
        <div style="  margin: 10px 0px 4px 0px;">任务列表 {{ areaName }}</div>
        <div class="frcc">
            <!-- <div style="font-size: 12px;" class="fcefe">
                <div>透明度决定生产力</div>
                <div>Transparent Factory</div>
            </div> -->
            <img src="/img/TransfactLogo.gif" alt="" style="height: 80px;">
        </div>
      
          
        </div>
        <div>
          <table style="font-size: 16px; width: auto; margin: 0px 20px">
            <thead
              style="
                color: white;
                background-color: rgb(var(--secondary-default));
              "
            >
              <tr>
                <th>工单号</th>
                <th>流转卡号</th>
                <th>工序号</th>
                <th>工序名称</th>
                <th>料号</th>
                <th>名称</th>
                <th>规格</th>
                <th>数量</th>
                <th style="min-width: 120px;">进度</th>
                <th style="min-width: 80px;">开始/结束（计划）</th>
                <th style="min-width: 80px;">计划结束时间</th>
                <th style="min-width: 80px;">开始/结束（实际）</th>
                <th style="min-width: 80px;">实际结束时间</th>
                <th>延迟</th>
                <th>优先级</th>
                <th>状态</th>
              </tr>
            </thead>
            <transition-group tag="tbody" name="tbody" class="tbody">
              <tr
                class="trDiv"
                v-for="resource in sortedRsList"
                :key="resource.rsId"
                :class="{
                  completed: resource.rsState === '完成',
                  delayed: resource.rsDelay === '延迟',
                }"
              >
                <td>{{ resource.rsWo }}</td>
                <td>{{ resource.rsLot }}</td>
                <td>{{ resource.rsSN }}</td>
                <td>{{ resource.rsName }}</td>
                <td>{{ resource.rsArt }}</td>
                <td>{{ resource.rsArtBesc1 }}</td>
                <td>{{ resource.rsArtBesc2 }}</td>
                <td>{{ resource.rsQuantity }}</td>
                <td>
                  <el-progress
                    :text-inside="true"
                    :stroke-width="18"
                    :percentage="parseInt(resource.rsSchedule)"
                    :status="getProgressStatus(resource)"
                  />
                </td>
                <td>{{ resource.rsEstimatedStartTime }}</td>
                <td>{{ resource.rsEstimatedEndTime }}</td>
                <td>{{ resource.rsActualStartTime }}</td>
                <td>{{ resource.rsActualEndTime }}</td>
                <td>{{ resource.rsDelay }}</td>
                <td>{{ resource.rsPriority }}</td>
                <td>{{ resource.rsState }}</td>
              </tr>
            </transition-group>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import details from "./SandBoxData";

export default {
  name: "TF2detailData.vue",
  props: {
    lineName: String,
  },
  data() {
    return {
      currentPage: 1,
      pageSize: 3,
      details: details,
      selectedArea: null,
      areaName: "",
    };
  },
  computed: {
    sortedRsList() {
      if (!this.selectedArea) {
        return [];
      }
      const completed = this.selectedArea.rsList.filter(
        (item) => item.rsState === "完成"
      );
      const notCompleted = this.selectedArea.rsList.filter(
        (item) => item.rsState !== "完成"
      );
      return [...notCompleted, ...completed];
    },
  },
  mounted() {
    setTimeout(() => {
      const areaData = this.details.find(
        (area) => area.areaName === this.lineName
      );
      if (areaData) {
        this.areaName = areaData.areaName;
        this.selectedArea = { ...areaData, rsList: [] }; // 初始化空数组
        this.addItemsSequentially(areaData.rsList);
      }
    }, 0);
  },
  methods: {
    addItemsSequentially(items, index = 0) {
      if (index < items.length) {
        this.selectedArea.rsList.push(items[index]);
        setTimeout(() => {
          this.addItemsSequentially(items, index + 1);
        }, 100); // 调整时间以控制加载动画的速度
      }
    },
    getProgressStatus(resource) {
      if (parseInt(resource.rsSchedule) === 100) {
        return null;
      } else if (resource.rsDelay === "延迟") {
        return "exception";
      } else {
        return "success";
      }
    },
  },
};
</script>

<style scoped>
.detailDataStyle {
  background-color: white;
  color: black;
  margin: 40px;
  height: calc(100% - 80px);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  padding-bottom: 40px;
  animation: fadeIn 1s ease-in-out
}



 @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }

td {
  padding: 4px 0;
  text-align: left;
}
th {
  padding: 4px 0;
  text-align: center;
  padding: 2px 4px;
}

tr {
  border: 1px solid red;
}
td {
  padding: 8px 8px;
  border-top: 1px solid rgb(var(--background-grey));
}
.trDiv:hover {
  background-color: rgb(var(--background-grey));
}
.delayed {
  background-color: var(--el-color-warning-light-9);
}
.completed {
  background-color: var(--el-color-primary-light-9);
  color: rgb(var(--text-default));
}

.tbody-enter-active,
.tbody-leave-active {
  transition: transform 0.5s ease, opacity 1s ease-out;
}
.tbody-enter-from,
.tbody-leave-to {
  transform: translateY(100px);
  opacity: 0;
}
</style>