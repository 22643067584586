<template>
 
  <!-- 数据分析类 -->
  <!-- 只有当 localDrawer 和 globalState.showEchartsData 都为 true 时才显示 red-box -->
  <!-- <div style="width: 100px; height: 100px; background-color: red">
    <a 
      href="https://demo.transfact-china.com/olerp/resstatus.do?ppIdRessource=56561&str1=lq-%E6%B3%A8%E5%A1%91%E8%AE%BE%E5%A4%87"
      >跳转回到DEMO</a
    >
  </div> -->

  <div v-if="localDrawer && globalState.showEchartsData" class="red-box">
    <TF2echartDataAnalysisVue
      :echartsConfig="echartsConfig"
      :userInput="userInput"
    ></TF2echartDataAnalysisVue>
  </div>

  <TF2detailDataVue
    v-if="showDetailData && localDrawer"
    class="red-box"
    :lineName="linename"
  >
  </TF2detailDataVue>


  <TF2ProjectReportVue class="red-box" v-if="showProjectInfo"></TF2ProjectReportVue>
  <!-- 信息汇总类 
      这里会做一个功能显示一个div，table信息显示查询的结果。然后一个信息总结 
  -->
  <!-- 功能类 
      这里会做一个询问的问题，比如现场生产有一个不良品，
      采购有一个不良品，系统会回复一个处理的流程，然后给出一个按钮，
      点击之后再左侧会显示对应的页面内。
  -->

  <el-drawer
    v-model="localDrawer"
    title="T-AI工业大模型"
    size="25%"
    modal="false"
    :append-to-body="true"
  >
    <div class="chat-container">
      <div class="chat-messages">
        <!-- 遍历聊天记录并显示 -->
        <div
          v-for="(message, index) in chatMessages"
          :key="index"
          :class="message.sender"
        >
          <!-- 圆形头像 -->
          <div v-if="message.sender === 'ai'" class="avatar">
            <img src="/img/ai.png" alt="T-AI" />
          </div>
          <div class="message-content-wrapper">
            <span class="message-content">
              <span v-html="message.text"></span>
              <!-- 显示光标，仅在打字过程中显示 -->
              <span
                v-if="
                  index === chatMessages.length - 1 &&
                  message.sender === 'ai' &&
                  typing
                "
                class="blinking-cursor"
                >●</span
              >
            </span>
            <!-- 在特定消息中显示按钮 -->
            <div v-if="message.showFunctionButton" class="button-wrapper">
              <div
                style="
                  width: 140px;
                  height: 40px;
                  background-color: rgb(var(--success-default));
                  margin-top: 10px;
                  margin-left: 0px;
                  text-align: center;
                  padding: 4px 10px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 4px;
                "
              >
                <a
                  href="https://demo.transfact-china.com/olerp/resstatus.do?ppIdRessource=42082&str1=CNC04"
                  target="_blank"
                >
                设备故障反馈
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 添加一个按钮，当点击时设置userMessage并发送 -->
      <div
        class="quick-send-button fccc"
        
        style="width: 100%; border-top: 1px solid #ccc"
      >
        <div
          style="
            font-size: 16px;
            position: relative;
            top: -12%;
            background-color: white;
            padding: 0px 8px;
          "
        >
          输入示例
        </div>
        <div
          style="
            width: 90%;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
          "
        >
          <button
            @click="sendPredefinedMessage"
            style="
              border: 2px dashed rgb(var(--success-default));
              line-height: 1.5em;
            "
            class="success-button"
          >
            <span style="color: rgb(var(--success-default))">[数据正常] </span
            ><br />稼动率
          </button>
          <button
            @click="sendPredefinedMessage1"
            style="
              border: 2px dashed rgb(var(--warning-default));
              line-height: 1.5em;
            "
            class="warning-button"
          >
            <span style="color: rgb(var(--warning-default))">[数据异常]</span>
            <br />完工率图表
          </button>

          <button
            @click="sendPredefinedMessage2"
            style="
              border: 2px dashed rgb(var(--secondary-default));
              line-height: 1.5em;

            "
            class="primary-button"
          >
            <span style="color: rgb(var(--secondary-default))"
              >[列表详情]
            </span>
            <br />生产线任务
          </button>

          <button
            @click="sendPredefinedMessage3"
            style="
              border: 2px dashed rgb(var(--secondary-default));
              line-height: 1.5em;
            "
            class="primary-button"
          >
            <span style="color: rgb(var(--secondary-default))"
              >[操作指导]
            </span>
            <br />设备异常
          </button>


          <button
            @click="sendPredefinedMessage5"
            style="
              border: 2px dashed rgb(var(--secondary-default));
              line-height: 1.5em;
            "
            class="primary-button"
          >
            <span style="color: rgb(var(--secondary-default))"
              >[项目管理]
            </span>
            <br />航亚周例会
          </button>
        </div>
      </div>
      <div class="chat-input">
        <input
          type="text"
          v-model="userInput"
          placeholder="请输入您的问题..."
          @keyup.enter="sendMessage"
        />
        <button @click="sendMessage">发送</button>
      </div>
    </div>
  </el-drawer>
</template>
  
  <script>
import { ref, computed, nextTick } from "vue";
import { globalState } from "@/globalState"; // 引入 globalState
import TF2echartDataAnalysisVue from "./TF2echartDataAnalysis.vue";
import TF2detailDataVue from "./TF2detailData.vue";
import TF2ProjectReportVue from './TF2ProjectReport.vue';

export default {
  name: "TF2eldrawer",
  components: {
    TF2echartDataAnalysisVue,
    TF2detailDataVue,
    TF2ProjectReportVue
  },
  props: {
    drawer: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const sendPredefinedMessage = () => {
      userInput.value = "今日设备稼动率是多少？请给我图表显示";
      sendMessage();
    };
    const sendPredefinedMessage1 = () => {
      userInput.value = "今日完工率是多少？请给我图表显示";
      sendMessage();
    };
    const sendPredefinedMessage2 = () => {
      userInput.value = "今日手动线工序列表有哪些？请显示详细数据";
      sendMessage();
    };
    const sendPredefinedMessage3 = () => {
      userInput.value = "设备CNC04现在故障了，我该怎么操作";
      sendMessage();
    };
    const sendPredefinedMessage4 = () => {
      userInput.value = "今日完工率是多少？请给我图表显示";
      sendMessage();
    };
    const sendPredefinedMessage5 = () => {
      userInput.value = "请显示航亚项目本周状况的汇总信息";
      sendMessage();
    };

    const showDetailData = ref(false); // 新增显示详细数据的状态
    const showProjectInfo = ref(false); // 新增显示详细数据的状态
    const linename = ref(""); // 初始化为一个空字符串或其他默认值
    const showFunctionButton = ref(false);

    const localDrawer = computed({
      get() {
        return props.drawer;
      },
      set(value) {
        emit("update:drawer", value);
      },
    });

    const userInput = ref("");
    const echartsConfig = ref(null);
    const hasUserInput = computed(() => {
      return (
        userInput.value.trim() !== "" &&
        chatMessages.value.some((msg) => msg.sender === "user")
      );
    });

    const chatMessages = ref([
      {
        sender: "ai",
        text: "我是T-AI，一个生产领域的大模型智能助手，请问你有什么想要了解的相关内容？",
      },
    ]);
    const typing = ref(false);
    let firstResponseContent = ""; // 用于存储第一次API调用的输出内容

    const prompt = `
  你是由 TRANSFACT 公司开发的 T-AI，用于回答生产相关的问题。以下是当前的设备稼动率信息：
  
  - **生产相关数据**: 
    - 航亚项目本周进度正常，当前进度是22.8%，项目组KPI评分为85分，较为良好。新增一条中风险项，需要项目负责人关注。
    - 今日设备平均稼动率是73%
    - 今日完工率是60%
    - 今日手动线工序列表：
      - 已经完工的：3条
      - 未完工的：2条
        - 其中延迟：1条
        - 其中正常：1条
  - **KPI 考核指标**: 设备稼动率额定为 50%；完工率KPI额定为80%；
  - **回复的语言**: 请根据客户输入的问题的语言进行调整
`;

    const prompt01 = `
  如果用户的问题中包含“用图表显示数据”，请在回答完问题后，做以下处理：
  
  1. 如果问题涉及到 KPI 相关的考核指标，在回复实际值时，请同时描述额定值。例如：“实际值为 73%，KPI 考核标准为 50%。”
  2. 如果用户的问题中包含了“图表显示”或“显示详细数据”，在正常回复完问题之后，必须在回复的末尾增加：**左侧已显示数据**，或**Data is shown on the left**,内容和语言不固定，表达清楚右侧显示数据的意思即可。
  3. 如果用户的问题中包含了“设备故障”，则需要向用户说明按照公司的规章制度对于设备故障的处理方式,处理方式如下：
     -1. 首先需要在MES系统内将设备状态调整为异常状态，并填写简单的故障描述
     -2. 系统会自动发送通知给设备的负责人
     -3. 设备负责人收到异常通知后需要第一时间赶到现场进行问题诊断和处理
     -4. 设备负责人需在设备故障记录表中填写设备异常的原因，处理方式，持续时间等详细信息。
     -5. 完成设备维修后，设备负责人需在系统内将设备状态调整为正常状态
     并在描述完规章制度的处理流程后，末尾增加“点击以下按钮，进行设备异常状态和故障描述：”的内容。
  4. 如果用户的问题中包含了“航亚项目本周状况”，则在正常回复完成之后，必须在回复的末尾增加：**左侧已显示项目概览**
  4. 回复的内容需要更条理，具有总结性，切记不要回答和问题无关的内容。
  
      `;

    const prompt02 = `
  根据以下用户的输入内容生成一个严格按照 JSON 格式的回复。如果用户请求中包含了“图表显示”数据，请返回如下格式的JSON对象：

  {
    "showEchartsData": true,
    "showDetailData": false,
    "echartsStyle": "pie",
    "title": "{{用户询问的数据标题}}",
    "data": [
      {"name": "{{数据标题}}", "value": {{实际数据值}}},
      {"name": "空闲", "value": 100 - {{实际数据值}}}
    ]
  }

  如果用户请求中包含了“显示详细数据”而不是“图表显示”，请返回如下格式的JSON对象：
  {
    "showEchartsData": false,
    "showDetailData": true,
    "linename": "{{从用户问题中提取的生产线名称}}",
    "details": "请填写详细数据的内容或其他相关信息"
  }

  linename的值应从用户的问题中提取出来，可选择的范围有[“自动线”，“手动线”，“非标线”，“焊接线”，“装配线”，“喷涂线”，“包装线”]。

  如果用户的问题中同时包含“图表显示”和“显示详细数据”，优先返回“图表显示”的JSON对象：
  {
    "showEchartsData": true,
    "showDetailData": false,
    "echartsStyle": "pie",
    "title": "{{用户询问的数据标题}}",
    "data": [
      {"name": "{{数据标题}}", "value": {{实际数据值}}},
      {"name": "空闲", "value": 100 - {{实际数据值}}}
    ]
  }

    如果用户请求中包含了“设备故障”，请返回如下格式的JSON对象：
 {
  "showFunctionButton": true,
}


    如果用户请求中包含了“航亚项目本周状况”，请返回如下格式的JSON对象：
 {
  "showProjectInfo": true,
}


  确保在返回的JSON对象中，"showEchartsData"和"showDetailData"和"showProjectInfo"，这三个字段中只能有一个为true。
  
  返回的JSON格式要求：
  返回的内容前后部分一定不要带\`\`\` json \`\`\`这种样式,
  返回的内容前后部分一定不要带\`\`\` json \`\`\`这种样式,
  返回的内容前后部分一定不要带\`\`\` json \`\`\`这种样式,
  返回的JSON中一定不要有注释说明信息！！！
  返回的JSON中一定不要有注释说明信息！！！
  返回的JSON中一定不要有注释说明信息！！！
`;

const sendMessage = async () => {
  const userMessage = userInput.value.trim();
  if (!userMessage) return;

  // 添加用户消息
  chatMessages.value.push({ sender: "user", text: userMessage });
  
  // 添加一个空的AI消息，并获取它的索引
  const aiMessageIndex = chatMessages.value.length;
  chatMessages.value.push({ sender: "ai", text: "", showFunctionButton: false });

  // 重置 echartsConfig 和 showFunctionButton
  echartsConfig.value = null;
  globalState.showEchartsData = false;

  try {
    typing.value = true;

    // 第一次 API 调用: 文本回复
    const firstResponse = await fetch(
      "https://api.fe8.cn/v1/chat/completions",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer sk-CqOHiuAoLaAS4Qd36IazH3HGWgn7fF9FO9S9jvNKDPVBAIbp", // 替换为您的API key
        },
        body: JSON.stringify({
          model: "gpt-4o-mini",
          messages: [
            { role: "system", content: `${prompt} ${prompt01}` },
            { role: "user", content: userMessage },
          ],
          stream: true,
        }),
      }
    );

    if (!firstResponse.ok) {
      throw new Error(`HTTP error! status: ${firstResponse.status}`);
    }

    const firstReader = firstResponse.body.getReader();
    const decoder = new TextDecoder("utf-8");
    let done = false;
    let aiMessage = "";

    while (!done) {
      const { value, done: readerDone } = await firstReader.read();
      done = readerDone;
      const chunk = decoder.decode(value, { stream: !done });

      const lines = chunk.split("\n").filter((line) => line.trim() !== "");
      for (const line of lines) {
        if (line.startsWith("data: ")) {
          const data = line.substring(6).trim();
          if (data === "[DONE]") {
            done = true;
            break;
          }
          try {
            const parsedData = JSON.parse(data);
            const content = parsedData.choices[0].delta.content;
            if (content) {
              firstResponseContent += content; // 累积第一次API的输出内容
              aiMessage += content;
              chatMessages.value[aiMessageIndex].text = aiMessage;
              await nextTick();
              await delay(150);
            }
          } catch (parseError) {
            console.error("JSON parse error:", parseError);
          }
        }
      }
    }

    // 第二次 API 调用: JSON 回复
    const secondRequestContent = `【${firstResponseContent} ${userMessage}】 \n"根据这段文字前一个段落的【】这个符号内的内容自动判断回复的语言"`;

    console.log("第二次API传递的内容:", secondRequestContent);

    const secondResponse = await fetch(
      "https://api.fe8.cn/v1/chat/completions",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer sk-CqOHiuAoLaAS4Qd36IazH3HGWgn7fF9FO9S9jvNKDPVBAIbp", // 替换为您的API key
        },
        body: JSON.stringify({
          model: "gpt-4o-mini",
          messages: [
            { role: "system", content: `${prompt} ${prompt02}` },
            { role: "user", content: secondRequestContent },
          ],
        }),
      }
    );

    if (!secondResponse.ok) {
      throw new Error(`HTTP error! status: ${secondResponse.status}`);
    }

    const jsonResponse = await secondResponse.json();

    const responseContent = jsonResponse.choices[0].message.content;
    console.log("第二次API的响应数据:", responseContent);

    let parsedContent;
    try {
      parsedContent = JSON.parse(responseContent);
      console.log("JSON转换之后", parsedContent);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return; // 如果解析失败，就直接返回
    }

    const showEchartsData = parsedContent.showEchartsData;
    showDetailData.value = parsedContent.showDetailData;
    showProjectInfo.value = parsedContent.showProjectInfo;
    linename.value = parsedContent.linename;
    const showFunctionButton = parsedContent.showFunctionButton || false;

    // 在正确的消息对象上设置 showFunctionButton
    chatMessages.value[aiMessageIndex].showFunctionButton = showFunctionButton;

    if (showEchartsData) {
      globalState.showEchartsData = true;
      echartsConfig.value = parsedContent;
    } else if (showDetailData.value) {
      globalState.showEchartsData = false; // 确保不会显示图表
      // 处理详细数据的逻辑
    }

    typing.value = false;
  } catch (error) {
    console.error("Error fetching AI response:", error);
    chatMessages.value.push({
      sender: "ai",
      text: "Sorry, there was an error processing your request.",
    });
    typing.value = false;
  }

  userInput.value = ""; // 清空用户输入
};


    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    return {
      localDrawer,
      userInput,
      hasUserInput,
      chatMessages,
      typing,
      sendMessage,
      sendPredefinedMessage, // 暴露给模板使用
      sendPredefinedMessage1, // 暴露给模板使用
      sendPredefinedMessage2, // 暴露给模板使用
      sendPredefinedMessage3, // 暴露给模板使用
      sendPredefinedMessage4, // 暴露给模板使用
      sendPredefinedMessage5, // 暴露给模板使用
      echartsConfig,
      globalState, // 将 globalState 暴露给模板使用
      showDetailData, // 新增showDetailData
      showProjectInfo,
      linename,
      showFunctionButton,
    };
  },
};
</script>
  
  <style scoped>
/* 红色方块的样式 */
.red-box {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 70%;
  height: 100%;
  background-color: transparent;
  z-index: 3000; /* 确保它显示在其他元素上方 */
}

/* 其他样式 */
.boxSize {
  position: relative;
  width: 30px;
  height: 30px;
  background-color: rgba(var(--warning-default), 1);
  cursor: pointer;
}

.boxSize:hover {
  opacity: 0.6;
}

.iconSize {
  position: relative;
  width: 28px;
  height: 28px;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  font-size: 16px;
  width: auto;
  line-height: 1.5em;
}

.user {
  color: #333;
  margin: 10px;
  text-align: right;
  padding: 12px 24px;
  background-color: #efefef;
  border-radius: 4px;
}

.ai {
  color: #333;
  font-weight: 400;
  margin: 10px;
  display: flex; /* 使内容与头像水平对齐 */
  align-items: start;
}

.avatar {
  flex-shrink: 0;
  width: 28px; /* 设置头像大小 */
  height: 28px;
  background-color: #000; /* 背景色为黑色 */
  border-radius: 50%; /* 使头像为圆形 */
  margin-right: 10px; /* 与文本间距 */
  display: flex;
  align-items: start;
  justify-content: flex-start;
  overflow: hidden; /* 隐藏溢出部分以保持圆形 */
}

.avatar img {
  height: 100%; /* 高度占满容器 */
  width: auto; /* 宽度自适应 */
  object-fit: contain; /* 保持图像比例 */
  display: block;
}

.message-content {
  margin-top: 0px;
  max-width: 80%; /* 限制消息内容的最大宽度 */
  word-wrap: break-word; /* 自动换行 */
  white-space: pre-wrap; /* 保留空格和换行符 */
  display: inline; /* 使光标紧跟文本内容 */
  align-items: end; /* 光标与文本内容垂直居中 */
}

.chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.chat-input button {
  padding: 5px 10px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

.chat-input button:hover {
  background-color: #0056b3;
}

/* 光标闪烁效果 */
.blinking-cursor {
  font-weight: bold;
  font-size: 16px;
  color: #333;
  animation: blink 0.8s steps(2, start) infinite;
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}

.quick-send-button {
  padding: 10px;
  text-align: center;
}

.quick-send-button button {
  padding: 10px 20px;
  border: none;
  width: 120px;
  margin: 8px 10px;

  color: #777;
  border: 1px dashed #777;
  border-radius: 4px;
  cursor: pointer;
}

.quick-send-button button:hover {
  background-color: white;
  /* color: white;
  font-weight: 600;
  border: 2px solid black; */
}

.success-button {
  background-color: rgba(218, 255, 208, 0.2);
  height: 60px;
}

.warning-button {
  background-color: rgba(253, 199, 199, 0.15);
  height: 60px;
}

.primary-button {
  background-color: rgba(150, 194, 250, 0.1);
  height: 60px;
}
</style>
  