

const details = [
  {
    areaName: "手动线",
    rsList: [
      {
        rsId: "10001",
        rsWo: "AB2401001-10",
        rsLot: "400189",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "在制",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401001-10",
        rsLot: "400200",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 100,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401002-10",
        rsLot: "400201",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "在制",
        rsDelay: "延迟",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401003-10",
        rsLot: "400202",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 100,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401004-10",
        rsLot: "400203",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 100,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
    ],
  },
  {
    areaName: "自动线",
    rsList: [
      {
        rsId: "10001",
        rsWo: "AB2401001-10",
        rsLot: "400189",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401001-10",
        rsLot: "400200",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401002-10",
        rsLot: "400201",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "延迟",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401003-10",
        rsLot: "400202",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401004-10",
        rsLot: "400203",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401004-10",
        rsLot: "400203",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401004-10",
        rsLot: "400203",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401004-10",
        rsLot: "400203",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401004-10",
        rsLot: "400203",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
    ],
  },
  {
    areaName: "非标线",
    rsList: [
      {
        rsId: "10001",
        rsWo: "AB2401001-10",
        rsLot: "400189",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "在制",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401001-10",
        rsLot: "400200",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401002-10",
        rsLot: "400201",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "在制",
        rsDelay: "延迟",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401003-10",
        rsLot: "400202",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401004-10",
        rsLot: "400203",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
    ],
  },
  {
    areaName: "焊接线",
    rsList: [
      {
        rsId: "10001",
        rsWo: "AB2401001-10",
        rsLot: "400189",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "在制",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401001-10",
        rsLot: "400200",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401002-10",
        rsLot: "400201",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "延迟",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401003-10",
        rsLot: "400202",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401004-10",
        rsLot: "400203",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
    ],
  },
  {
    areaName: "装配线",
    rsList: [
      {
        rsId: "10001",
        rsWo: "AB2401001-10",
        rsLot: "400189",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401001-10",
        rsLot: "400200",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401002-10",
        rsLot: "400201",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "延迟",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401003-10",
        rsLot: "400202",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "在制",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401004-10",
        rsLot: "400203",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
    ],
  },
  {
    areaName: "喷涂线",
    rsList: [
      {
        rsId: "10001",
        rsWo: "AB2401001-10",
        rsLot: "400189",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401001-10",
        rsLot: "400200",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401002-10",
        rsLot: "400201",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "在制",
        rsDelay: "延迟",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401003-10",
        rsLot: "400202",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401004-10",
        rsLot: "400203",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
    ],
  },
  {
    areaName: "包装线",
    rsList: [
      {
        rsId: "10001",
        rsWo: "AB2401001-10",
        rsLot: "400189",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "在制",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401001-10",
        rsLot: "400200",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "在制",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401002-10",
        rsLot: "400201",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "在制",
        rsDelay: "延迟",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401003-10",
        rsLot: "400202",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "在制",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401004-10",
        rsLot: "400203",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
    ],
  },
  {
    areaName: "包装线02",
    rsList: [
      {
        rsId: "10001",
        rsWo: "AB2401001-10",
        rsLot: "400189",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "在制",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401001-10",
        rsLot: "400200",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "在制",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401002-10",
        rsLot: "400201",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "在制",
        rsDelay: "延迟",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401003-10",
        rsLot: "400202",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "在制",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401004-10",
        rsLot: "400203",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
    ],
  },
  {
    areaName: "包装线03",
    rsList: [
      {
        rsId: "10001",
        rsWo: "AB2401001-10",
        rsLot: "400189",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "在制",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401001-10",
        rsLot: "400200",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "在制",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401002-10",
        rsLot: "400201",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "在制",
        rsDelay: "延迟",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401003-10",
        rsLot: "400202",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "在制",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401004-10",
        rsLot: "400203",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
    ],
  },
  {
    areaName: "包装线04",
    rsList: [
      {
        rsId: "10001",
        rsWo: "AB2401001-10",
        rsLot: "400189",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "在制",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401001-10",
        rsLot: "400200",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "在制",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401002-10",
        rsLot: "400201",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "在制",
        rsDelay: "延迟",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401003-10",
        rsLot: "400202",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "在制",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
      {
        rsId: "10001",
        rsWo: "AB2401004-10",
        rsLot: "400203",
        rsSN: "10",
        rsName: "剪",
        rsArea: "手动线",
        rsRes: "资源01",
        rsEstimatedStartTime: "计划开始时间",
        rsEstimatedEndTime: "计划结束时间",
        rsActualStartTime: "实际开始时间",
        rsActualEndTime: "实际结束时间",
        rsSchedule: 80,
        rsArt: "ART001",
        rsArtBesc1: "产品01",
        rsArtBesc2: "BL23-0223-1",
        rsQuantity: "2",
        rsState: "完成",
        rsDelay: "1h23min",
        rsPriority: "普通",
      },
    ],
  },
];

export default details;
