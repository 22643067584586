<template>
  <div class="projectInfo">
    <div
      style="
        background-color: white;
        color: #333;
        margin: 40px;
        height: calc(100% - 80px);
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
        border-radius: 4px;
        padding-bottom: 40px;
        font-size: 20px;
      "
    >
      <!-- logo -->
      <div class="frcsb" style="height: 8%">
        <img
          src="/img/HYLOGO.png"
          alt=""
          style="height: 28px; margin-left: 40px; margin-top: 20px"
        />
      </div>
      <!-- KPI -->
      <div class="frcsa" style="height: 50%; margin: 0px 20px">
        <!-- 项目全局概览 -->
        <div style="width: 30%; height: 90%" class="bgcard">
          <div class="incard" style="height: calc(100% - 32px)">
            <div class="frcfs">
              <div>
                <announcement theme="outline" size="32" fill="#0062a7" />
              </div>
              <div
                style="
                  margin-left: 8px;
                  font-weight: 600;
                  color: rgb(var(--secondary-default));
                "
              >
                项目全局概览
              </div>
            </div>
            <div
              class="fcssb"
              style="
                margin: 10px 20px 10px 20px;
                font-size: 16px;
                height: calc(100% - 60px);
              "
            >
              <div class="itemStyle">
                <span style="font-weight: 600">● 项目名称</span
                >：航亚MOM系统（第二期）
              </div>
              <div class="itemStyle">
                <span style="font-weight: 600">● 启动日期</span>：2024年8月12日
              </div>
              <div class="itemStyle">
                <span style="font-weight: 600">● 累计工作日</span>：15天
              </div>
              <div class="itemStyle">
                <span style="font-weight: 600">● 预计上线时间</span
                >：2025年1月1日
              </div>
              <div class="itemStyle">
                <span style="font-weight: 600">● 关键里程碑</span
                >：需求定义与确认<span style="font-weight: 400">（2 / 6）</span>
              </div>
              <div class="itemStyle">
                <span style="font-weight: 600">● 风险状况</span>：中
              </div>
            </div>
          </div>
        </div>
        <!-- 项目经理KPI -->
        <div style="width: 30%; height: 90%" class="bgcard">
          <div class="incard" style="height: calc(100% - 32px)">
            <div class="frcfs">
              <div>
                <waterfalls-v theme="outline" size="30" fill="#0062a7" />
              </div>
              <div
                style="
                  margin-left: 8px;
                  font-weight: 600;
                  color: rgb(var(--secondary-default));
                "
              >
                项目经理KPI
              </div>
            </div>
            <div
              class="fcssb"
              style="
                margin: 10px 20px;
                font-size: 16px;
                height: calc(100% - 60px);
              "
            >
              <div class="itemStyle">
                <span style="font-weight: 600">● 项目经理</span>：朱金晨
              </div>
              <div class="itemStyle">
                <span style="font-weight: 600">● 沟通核心业务流程（项）</span
                >：<span style="color: #3ba272; font-weight: 600">已达标</span
                >（7/5）
              </div>
              <div class="itemStyle">
                <span style="font-weight: 600">● 输出下周计划时间表</span
                >：<span style="color: #ee6666; font-weight: 600">未达标</span>
              </div>
              <div class="itemStyle">
                <span style="font-weight: 600">● 更新/输出双周计划</span>：<span
                  style="color: #3ba272; font-weight: 600"
                  >已达标</span
                >
              </div>
              <div class="itemStyle">
                <span style="font-weight: 600">● 更新问题追踪表</span>：<span
                  style="color: #3ba272; font-weight: 600"
                  >已达标</span
                >
              </div>
              <div class="itemStyle">
                <span style="font-weight: 600">● 更新项目风险评估表</span
                >：<span style="color: #3ba272; font-weight: 600">已达标</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 项目团队KPI -->
        <div style="width: 30%; height: 90%" class="bgcard">
          <div class="incard" style="height: calc(100% - 32px)">
            <div class="frcfs">
              <div>
                <waterfalls-h theme="outline" size="30" fill="#0062a7" />
              </div>
              <div
                style="
                  margin-left: 8px;
                  font-weight: 600;
                  color: rgb(var(--secondary-default));
                "
              >
                团队KPI
              </div>
            </div>
            <div
              class="fcssb"
              style="
                margin: 10px 20px;
                font-size: 16px;
                height: calc(100% - 60px);
              "
            >
              <div class="itemStyle">
                <span style="font-weight: 600">● 会议纪要</span>：<span
                  style="color: #3ba272; font-weight: 600"
                  >已达标</span
                >（6/5）
              </div>
              <div class="itemStyle">
                <span style="font-weight: 600">● 细化业务场景流程图及说明</span
                >：<span style="color: #3ba272; font-weight: 600">已达标</span
                >（18/10）
              </div>
              <div class="itemStyle">
                <span style="font-weight: 600">● 现场巡视与沟通时长(h)</span
                >：<span style="color: #ee6666; font-weight: 600">未达标</span
                >（1.2/3）
              </div>
              <div class="itemStyle">
                <span style="font-weight: 600">● 项目文件归档</span>：<span
                  style="color: #3ba272; font-weight: 600"
                  >已达标</span
                >
              </div>
              <div class="itemStyle">
                <span style="font-weight: 600">● 会议参与率（%）</span>：<span
                  style="color: #3ba272; font-weight: 600"
                  >已达标</span
                >（86/80）
              </div>
              <div class="itemStyle">
                <span style="font-weight: 600">● 文件一次通过率（%）</span
                >：<span style="color: #ee6666; font-weight: 600">未达标</span
                >（63/80）
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 待做事项汇总 及 关键里程碑进度，预计完成时间  及 风险项目 -->
      <div class="frcsa" style="height: 30%; margin: 0px 20px">
        <div class="bgcard" style="width: 63.4%; height: 90%">
          <div class="incard">
            <div class="frcfs">
              <div>
                <view-grid-detail theme="outline" size="32" fill="#0062a7" />
              </div>
              <div
                style="
                  margin-left: 8px;
                  font-weight: 600;
                  color: rgb(var(--secondary-default));
                "
              >
                SOW完成情况
              </div>
            </div>
            <table style="width: 100%; font-size: 16px; padding-bottom: 40px">
              <tbody>
                <tr style="font-weight: 600">
                  <td style="width: 20%">项目关键节点</td>
                  <td style="width: 30%">进度</td>
                  <td style="text-align: center; width: 30%">预计完成时间</td>
                  <td style="text-align: center; width: 20%">延迟</td>
                </tr>
                <tr>
                  <td>业务调研</td>
                  <td>
                    <el-progress
                      :percentage="70"
                      :stroke-width="16"
                      :text-inside="true"
                      status="success"
                      striped
                      striped-flow
                      :duration="10"
                      style="font-size: 4px"
                    />
                  </td>
                  <td style="text-align: center">2024年9月15日</td>
                  <td
                    style="text-align: center"
                    v-html="calculateDelay('2024-09-15')"
                  ></td>
                </tr>
                <tr>
                  <td>蓝图制定</td>
                  <td>
                    <el-progress
                      :percentage="80"
                      :stroke-width="16"
                      :text-inside="true"
                      status="success"
                      striped
                      striped-flow
                      :duration="10"
                      style="font-size: 4px"
                    />
                  </td>
                  <td style="text-align: center">2024年9月30日</td>
                  <td
                    style="text-align: center"
                    v-html="calculateDelay('2024-09-30')"
                  ></td>
                </tr>
                <tr>
                  <td>功能开发</td>
                  <td>
                    <el-progress
                      :percentage="22.5"
                      :stroke-width="16"
                      :text-inside="true"
                      status="success"
                      striped
                      striped-flow
                      :duration="10"
                      style="font-size: 4px"
                    />
                  </td>
                  <td style="text-align: center">2024年10月15日</td>
                  <td
                    style="text-align: center"
                    v-html="calculateDelay('2024-10-15')"
                  ></td>
                </tr>
                <tr>
                  <td>UAT测试</td>
                  <td>
                    <el-progress
                      :percentage="0"
                      :stroke-width="16"
                      :text-inside="true"
                      status="success"
                      striped
                      striped-flow
                      :duration="10"
                      style="font-size: 4px"
                      ><div style="color: #333">0%</div></el-progress
                    >
                  </td>
                  <td style="text-align: center">2024年10月15日</td>
                  <td
                    style="text-align: center"
                    v-html="calculateDelay('2024-10-15')"
                  ></td>
                </tr>
                <tr>
                  <td>上线准备</td>
                  <td>
                    <el-progress
                      :percentage="0"
                      :stroke-width="16"
                      :text-inside="true"
                      status="success"
                      striped
                      striped-flow
                      :duration="10"
                      style="font-size: 4px"
                      ><div style="color: #333">0%</div></el-progress
                    >
                  </td>
                  <td style="text-align: center">2024年10月31日</td>
                  <td
                    style="text-align: center"
                    v-html="calculateDelay('2024-10-31')"
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="bgcard" style="width: 30%; height: 90%">
          <div class="incard">
            <div class="frcfs">
              <div>
                <caution theme="outline" size="30" fill="#0062a7" />
              </div>
              <div
                style="
                  margin-left: 8px;
                  font-weight: 600;
                  color: rgb(var(--secondary-default));
                "
              >
                风险项
              </div>
            </div>
            <div class="frsfs" style="font-size: 16px;">
              <div>
                <span style="font-weight: 600">总计</span>：4
                <div><span style="font-weight: 600">本周新增</span>：1</div>
              </div>
              <div
                id="riskPieChart"
                style="width: 240px; height: 200px; margin-left: 20px"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <!-- 总进度 -->
      <div style="margin: 40px; height: 20%">
        <table
          style="width: 100%; padding: 4px 10px; background-color: white"
          class="bgcard"
        >
          <tbody>
            <tr>
              <td style="width: 8%">
                <div
                  style="
                    padding: 4px 8px;
                    /* border: 1px solid rgb(var(--secondary-default)); */
                    text-align: center;
                    border-radius: 2px;
                    font-weight: 900;
                    margin-right: 10px;
                    color: rgb(var(--secondary-default));
                    /* box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.05); */
                  "
                >
                  <!-- <div class="frcfs">
                  <div style="margin-top: 6px; margin-right: 8px;"><hourglass-full theme="outline" size="24" fill="#0062a7"/></div>
                  
                </div> -->
                  <div>总进度</div>
                </div>
              </td>
              <td style="width: 90%">
                <el-progress
                  :percentage="22.8"
                  :stroke-width="30"
                  striped
                  striped-flow
                  :duration="10"
                  style="margin-left: 10px"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: "TF2ProjectReport",
  methods: {
    calculateDelay(expectedDate) {
      const currentDate = new Date();
      const expected = new Date(expectedDate);
      const timeDiff = currentDate - expected;
      const dayDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      return dayDiff >= 0
        ? `<span style="color:#ee6666;font-weight:600">${dayDiff}天</span>`
        : `<span style="color:#3ba272;font-weight:600">-${Math.abs(
            dayDiff
          )}天</span>`;
    },
    initRiskPieChart() {
      const chartDom = document.getElementById('riskPieChart');
      const myChart = echarts.init(chartDom);
      const option = {
        color:['#ee6666','#fac858'],
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'right'
        },
        series: [
          {
            name: '风险类型',
            type: 'pie',
            radius: '50%',
            data: [
              { value: 1, name: '高风险' },
              { value: 3, name: '中风险' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      myChart.setOption(option);
    }
  },
  mounted() {
    this.initRiskPieChart();
  }
};
</script>

<style scoped>
tr {
  height: 28px;
}

.bgcard {
  background-color: rgb(var(--background-grey-01));
  border-radius: 8px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
}

.incard {
  margin: 16px 20px;
}

/* .itemStyle{
  margin: 4px 0px;
} */

 .projectInfo{
  animation: fadeIn 1s ease-in-out
  }


 @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-100px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
</style>